import React from 'react'
import styles from './emailform.module.scss'

class EmailForm extends React.Component {
  render() {
    return (
      <form
        className={styles.emailform}
        data-netlify="true"
        method="POST"
        name="contact"
        netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p class="hidden">
          <label>
            Don’t fill this out if you're human:
            <input name="bot-field" />
          </label>
        </p>
        <label className={styles.label} htmlFor="name">
          Your name
        </label>
        <input
          id="name"
          required="required"
          className={styles.name}
          type="text"
          name="name"
        />
        <label className={styles.label} htmlFor="email">
          Your email address
        </label>
        <input
          id="email"
          required="required"
          className={styles.email}
          type="email"
          name="replyto"
        />
        <label className={styles.label} htmlFor="subject">
          Subject
        </label>
        <input
          id="subject"
          required="required"
          className={styles.subject}
          type="text"
          name="subject"
        />
        <label className={styles.label} htmlFor="body">
          Inquiry
        </label>
        <textarea
          id="body"
          required="required"
          className={styles.body}
          type="text"
          name="body"
        />
        <input className={styles.submit} type="submit" value="Send" />
      </form>
    )
  }
}

export default EmailForm
