import React from 'react'
import { graphql } from 'gatsby'
import PageTitle from '../components/PageTitle.js'
// import SocialContacts from '../components/SocialContacts.js'

import NavLayout from '../components/NavLayout.js'
import EmailForm from '../components/EmailForm.js'
import styles from './contact.module.scss'

export default ({ data, location }) => {
  const { Email } = data.allStrapiContact.edges[0].node
  return (
    <NavLayout>
      <PageTitle title="Contact" />
      <div className={styles.contactinfo}>
        <EmailForm
          subject={location.state ? location.state.subject : ''}
          emailTo={Email}
        />
        {/* <SocialContacts /> */}
      </div>
    </NavLayout>
  )
}

export const query = graphql`
  query {
    allStrapiContact {
      edges {
        node {
          id
          Email
        }
      }
    }
  }
`
